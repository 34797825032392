import { Text, View } from "react-native";
import { Buttons } from "../../Buttons";
import { Board } from "../../components/Board";
import { NumberButton } from "../../components/NumberButton";
import { useBoard } from "../../context/BoardContext";
import { useTheme } from "../../context/ThemeContext";
import { DifficultyName } from "../../utils/sudoku";

export function Game() {
  const { difficulty } = useBoard();
  const { theme } = useTheme();
  return (
    <>
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          justifyContent: "space-between",
          width: 374,
        }}
      >
        <Text style={{ marginBottom: 4 }}>Mistakes: 0 / 3</Text>
        <View
          style={{
            alignItems: "center",
            position: "absolute",
            left: 0,
            right: 0,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: "700",
              color: theme.primary,
              marginBottom: 4,
            }}
          >
            Score: {1000}
          </Text>
          <Text style={{ marginBottom: 4 }}>{DifficultyName[difficulty]}</Text>
        </View>
        <Text style={{ marginBottom: 4 }}>{"00:00"}</Text>
      </View>
      <Board />
      <View style={{ flexDirection: "row", marginTop: 28 }}>
        <Buttons />
      </View>
      <View style={{ flexDirection: "row", marginTop: 28 }}>
        {Array.from({ length: 9 }).map((_, index) => (
          <NumberButton key={index} value={index + 1} />
        ))}
      </View>
    </>
  );
}
