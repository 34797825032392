export type ThemeColors = {
  primary: string;
  secondary: string;
  text: string;
};

export const lightTheme: ThemeColors = {
  primary: "#304ffe",
  secondary: "#82b1ff",
  text: "#000000",
};
