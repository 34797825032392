import { Text, TouchableOpacity, View } from "react-native";
import { useBoard } from "./context/BoardContext";
import { MaterialCommunityIcons, Foundation } from "@expo/vector-icons";
import { SoundPlayer } from "./utils/SoundPlayer";
import { useTheme } from "./context/ThemeContext";

export function Buttons() {
  const { activeCell, setCellValue, isPencilEnabled, setPencilEnabled } =
    useBoard();
  const { theme } = useTheme();
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity
        style={{
          alignItems: "center",
          width: 40,
          marginHorizontal: 8,
        }}
        onPress={() => setCellValue(activeCell, undefined)}
      >
        <MaterialCommunityIcons
          name="eraser"
          allowFontScaling={false}
          size={24}
        />
        <Text style={{ marginTop: 4 }}>{"Erase"}</Text>
      </TouchableOpacity>

      <TouchableOpacity
        style={{
          alignItems: "center",

          marginHorizontal: 8,
        }}
        onPress={() => {
          SoundPlayer.playPen();
          setPencilEnabled(!isPencilEnabled);
        }}
      >
        <Foundation
          name="pencil"
          allowFontScaling={false}
          color={isPencilEnabled ? theme.primary : "black"}
          size={24}
        />
        <Text
          style={{
            color: isPencilEnabled ? theme.primary : "black",
            marginTop: 4,
            fontWeight: "500",
          }}
        >
          {"Pencil"}
        </Text>
      </TouchableOpacity>
    </View>
  );
}
