import { StyleSheet, Text, TouchableWithoutFeedback, View } from "react-native";
import { ICell, useBoard } from "../context/BoardContext";
import Animated, {
  useSharedValue,
  withTiming,
  withSequence,
  useAnimatedStyle,
  Easing,
} from "react-native-reanimated";
import { useEffect } from "react";
import { useTheme } from "../context/ThemeContext";
import { ThemeColors } from "../utils/colors";

export function Cell({ index }: { index: number }) {
  const { theme } = useTheme();

  const { activeCell, setActiveCell, cellValues } = useBoard();
  const { value, correctValue, isPreFilled, pencilValues } =
    cellValues[index - 1];
  const { value: activeValue } = cellValues[activeCell - 1];

  /*   const randomWidth = useSharedValue(0);

  const style = useAnimatedStyle(() => {
    return {
      transform: [{ scale: randomWidth.value }],
    };
  }, [activeCell]);

  useEffect(() => {
    if (value && activeValue === value) {
      randomWidth.value = withSequence(
        withTiming(1, { duration: 0 }),
        withTiming(1.25, { duration: 300 }),
        withTiming(1, { duration: 300 }),
        withTiming(0, { duration: 0 })
      );
    }
  }, [activeValue, value, activeCell]); */

  return (
    <View
      style={{
        position: "relative",
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: "gray",
      }}
    >
      {/*  <Animated.View
        style={[
          {
            width: 40,
            top: 0,
            position: "absolute",
            zIndex: -1,
            height: 40,
            borderColor: "black",
            overflow: "visible",
            borderWidth: 4,
          },
          style,
        ]}
      /> */}
      <TouchableWithoutFeedback
        onPressIn={() => {
          setActiveCell(index);
        }}
      >
        <View
          style={{
            width: 40,
            height: 40,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: getBackgroundColor(
              theme,
              value,
              correctValue,
              activeCell,
              activeValue,
              index
            ),
          }}
        >
          {!value ? (
            [0, 1, 2].map((row) => (
              <View key={row} style={styles.row}>
                {[row * 3 + 1, row * 3 + 2, row * 3 + 3].map((val) => (
                  <Text
                    key={val}
                    style={[
                      styles.pencilNumber,
                      /*  {
                        backgroundColor:
                          pencilValues[val] && val === activeValue
                            ? theme.secondary
                            : "transparent",
                      }, */
                    ]}
                  >
                    {pencilValues[val] ? val : ""}
                  </Text>
                ))}
              </View>
            ))
          ) : (
            <Text
              allowFontScaling={false}
              style={{
                textAlign: "center",
                fontSize: 28,

                color: isPreFilled ? "black" : "blue",
              }}
            >
              {value}
            </Text>
          )}
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}
function getBackgroundColor(
  theme: ThemeColors,
  value: number,
  correctValue: number,
  activeCell: number,
  activeValue: number,
  index
) {
  // Error
  if (value && value !== correctValue) {
    return "#ef9a9a";
  }

  // Highlight same values
  if (activeValue === value && !!activeValue) {
    return theme.secondary;
  }

  // active empty cell
  if (index === activeCell) {
    return theme.secondary;
  }

  // impact boundary
  if (shouldHighlight(index, activeCell)) {
    return "#dddddd";
  }

  return "transparent";
}

function shouldHighlight(index: number, activeCell: number): boolean {
  if (Math.floor((index - 1) / 9) === Math.floor((activeCell - 1) / 9)) {
    // Same Row
    return true;
  }
  if ((index - 1) % 9 === (activeCell - 1) % 9) {
    // Same Column
    return true;
  }

  const activeCol = (activeCell - 1) % 9;
  const activeRow = Math.floor((activeCell - 1) / 9);

  const col = (index - 1) % 9;
  const row = Math.floor((index - 1) / 9);

  if (
    Math.floor(activeCol / 3) === Math.floor(col / 3) &&
    Math.floor(activeRow / 3) === Math.floor(row / 3)
  ) {
    // Same box
    return true;
  }
  return false;
}

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    marginHorizontal: 2,
    flex: 1,
    width: "100%",
  },
  pencilNumber: {
    fontSize: 11,
    flex: 1,
    textAlign: "center",
  },
});
