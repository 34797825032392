import { StyleSheet, View } from "react-native";
import { Cell } from "./Cell";

export function Set({ index }: { index: number }) {
  const baseIndex = (index - 1) * 3;
  return (
    <View
      style={{
        flexDirection: "column",
        borderLeftWidth: 1,
        borderTopWidth: 1,
      }}
    >
      <View style={styles.row}>
        <Cell index={baseIndex + 1} />
        <Cell index={baseIndex + 2} />
        <Cell index={baseIndex + 3} />
      </View>
      <View style={styles.row}>
        <Cell index={baseIndex + 9 + 1} />
        <Cell index={baseIndex + 9 + 2} />
        <Cell index={baseIndex + 9 + 3} />
      </View>
      <View style={styles.row}>
        <Cell index={baseIndex + 18 + 1} />
        <Cell index={baseIndex + 18 + 2} />
        <Cell index={baseIndex + 18 + 3} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  row: { flexDirection: "row" },
});
