import { useEffect } from "react";
import { View } from "react-native";
import { useBoard } from "../context/BoardContext";
import { Set } from "./Set";
import Animated, {
  useSharedValue,
  withTiming,
  withSequence,
  useAnimatedStyle,
  Easing,
} from "react-native-reanimated";

export function Board() {
  const { activeCell, setActiveCell, cellValues } = useBoard();

  const scaleValue = useSharedValue(0);

  const style = useAnimatedStyle(() => {
    return {
      transform: [{ scale: scaleValue.value }],
    };
  }, [activeCell]);

  useEffect(() => {
    scaleValue.value = withSequence(
      withTiming(1, { duration: 0 }),
      withTiming(1.2, { duration: 250 }),
      withTiming(1, { duration: 250 }),
      withTiming(0, { duration: 0 })
    );
  }, [activeCell]);

  return (
    <View
      style={{
        flexDirection: "column",
        borderWidth: 1,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <Set index={1} />
        <Set index={2} />
        <Set index={3} />
      </View>
      <View style={{ flexDirection: "row" }}>
        <Set index={10} />
        <Set index={11} />
        <Set index={12} />
      </View>
      <View style={{ flexDirection: "row" }}>
        <Set index={19} />
        <Set index={20} />
        <Set index={21} />
      </View>
      {cellValues[activeCell - 1].value &&
        cellValues.map((cell, i) => {
          if (cell.value !== cellValues[activeCell - 1].value) {
            return null;
          }
          const index = i;

          const x = index % 9;
          const y = Math.floor(index / 9);
          return (
            <Animated.View
              key={i}
              style={[
                {
                  width: 40,
                  top: 40 * y + (y + 1 + Math.floor(y / 3)),
                  left: 40 * x + (x + 1 + Math.floor(x / 3)),
                  position: "absolute",
                  zIndex: 1,
                  height: 40,
                  borderColor: "white",
                  overflow: "visible",
                  borderWidth: 4,
                },
                style,
              ]}
            />
          );
        })}
    </View>
  );
}
