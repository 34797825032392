import React, { ReactNode, useState } from "react";
import { createContext, useContext } from "react";
import { lightTheme, ThemeColors } from "../utils/colors";

export type IThemeContext = {
  theme: ThemeColors;
  setTheme: (theme: ThemeColors) => void;
};

const DEFAULT: IThemeContext = {
  theme: lightTheme,
  setTheme: () => {},
};

export const ThemeContext = createContext<IThemeContext>(DEFAULT);

export function ThemeContextProvider({ children }: { children: ReactNode }) {
  const [theme, setTheme] = useState(lightTheme);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  return useContext(ThemeContext);
}
