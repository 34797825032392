import React, { ReactNode, useEffect, useState } from "react";
import { createContext, useContext } from "react";
import { Difficulty, generate, print_board, solve } from "../utils/sudoku";
import { SoundPlayer } from "../utils/SoundPlayer";

export type IBoardContext = {
  activeCell: number;
  difficulty: Difficulty;
  cellValues: ICell[];
  setActiveCell: (activeCell: number) => void;
  setCellValue: (index: number, value: number) => void;
  remainingCounts: { [key: number]: number };
  isPencilEnabled: boolean;
  setPencilEnabled: (enabled: boolean) => void;
};

export const BoardContext = createContext<IBoardContext>({
  activeCell: 41,
  difficulty: "easy",
  cellValues: [],
  setActiveCell: () => {},
  setCellValue: () => {},
  isPencilEnabled: false,
  setPencilEnabled: () => {},
  remainingCounts: {},
});

export type ICell = {
  value: number | undefined;
  correctValue: number;
  isPreFilled: boolean;
  pencilValues: { [key: number]: boolean };
};

function generateGame(difficulty: Difficulty) {
  let game = generate(difficulty);
  console.log(print_board(game));
  let solution = solve(game).split("");
  return solution.map<ICell>((value, index) => ({
    value: game[index] !== "." ? parseInt(value) : undefined,
    isPreFilled: game[index] !== ".",
    correctValue: parseInt(value),
    pencilValues: {
      /* [parseInt(value)]: true */
    },
  }));
}

function calculateCounts(cells: ICell[]) {
  const remainingCounts = {
    1: 9,
    2: 9,
    3: 9,
    4: 9,
    5: 9,
    6: 9,
    7: 9,
    8: 9,
    9: 9,
  };
  cells.forEach((c) => {
    if (c.value === c.correctValue) {
      remainingCounts[c.value] -= 1;
    }
  });
  return remainingCounts;
}

export function BoardContextProvider({ children }: { children: ReactNode }) {
  const [activeCell, setActiveCell] = useState(41);
  const [difficulty, setDifficulty] = useState<Difficulty>();

  const [cellValues, setCellValues] = useState<ICell[]>();
  const [remainingCounts, setRemainingCounts] = useState<{}>();
  const [isPencilEnabled, setPencilEnabled] = useState(false);

  useEffect(() => {
    const difficulty: Difficulty = "insane";
    const game = generateGame(difficulty);
    setDifficulty(difficulty);
    setCellValues(game);
    setRemainingCounts(calculateCounts(game));
  }, []);

  const setCellPencilValue = (index: number, value: number) => {
    const updated = cellValues.map((cell, cellIndex) => {
      if (cellIndex === index - 1) {
        if (value === undefined) {
          cell.pencilValues = {};
        } else {
          cell.pencilValues[value] = !cell.pencilValues[value];
        }
        return { ...cell };
      }
      return cell;
    });
    setCellValues(updated);
  };
  const setCellValue = (index: number, value: number) => {
    var cell = cellValues[index - 1];

    if (cell.isPreFilled || cell.value === cell.correctValue) {
      return false;
    }

    if (isPencilEnabled) {
      setCellPencilValue(index, value);
      return;
    }

    const updated = cellValues.map((cell, cellIndex) => {
      if (cellIndex === index - 1) {
        cell.value = value;
        if (value === undefined) {
          cell.pencilValues = {};
        }
        return { ...cell, value };
      }
      return cell;
    });
    if (value !== undefined) {
      if (cell.correctValue === value) {
        SoundPlayer.playPop();
      } else {
        SoundPlayer.playWrong();
      }
    }
    setCellValues(updated);
    setRemainingCounts(calculateCounts(updated));
  };

  if (!cellValues) {
    return null;
  }
  return (
    <BoardContext.Provider
      value={{
        activeCell,
        difficulty,
        setActiveCell,
        cellValues,
        setCellValue,
        remainingCounts,
        isPencilEnabled,
        setPencilEnabled,
      }}
    >
      {children}
    </BoardContext.Provider>
  );
}

export function useBoard() {
  return useContext(BoardContext);
}
