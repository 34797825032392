import { Text, TouchableOpacity, View } from "react-native";
import { useBoard } from "../context/BoardContext";
import { useTheme } from "../context/ThemeContext";

export function NumberButton({ value }: { value: number }) {
  const {
    activeCell,
    setCellValue,
    cellValues,
    remainingCounts,
    isPencilEnabled,
  } = useBoard();
  const { theme } = useTheme();
  return (
    <View style={{ marginHorizontal: 2 }}>
      <TouchableOpacity
        style={{
          alignItems: "center",
          //   borderWidth: 2,
          borderRadius: 8,
          width: 38,
          backgroundColor: "#fff",
          opacity:
            remainingCounts[value] === 0
              ? 0
              : isPencilEnabled
              ? cellValues[activeCell - 1].pencilValues[value]
                ? 1
                : 0.5
              : 1,
        }}
        disabled={remainingCounts[value] === 0}
        onPress={() => setCellValue(activeCell, value)}
      >
        <Text style={{ fontSize: 32, color: theme.primary, fontWeight: "500" }}>
          {value}
        </Text>
        <Text style={{ fontSize: 12, marginBottom: 4 }}>
          {remainingCounts[value]}
        </Text>
      </TouchableOpacity>
    </View>
  );
}
