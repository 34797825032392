import React from "react";
import { StatusBar } from "expo-status-bar";
import { StyleSheet, View } from "react-native";
import { BoardContextProvider } from "./context/BoardContext";
import { SoundPlayer } from "./utils/SoundPlayer";
import { ThemeContextProvider } from "./context/ThemeContext";
import { Game } from "./screens/Game/Game";

export default function App() {
  return (
    <View style={styles.container}>
      <StatusBar style="auto" />
      <ThemeContextProvider>
        <BoardContextProvider>
          <Game />
        </BoardContextProvider>
      </ThemeContextProvider>
    </View>
  );
}

SoundPlayer.init();

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#eeeeee",
    alignItems: "center",
    justifyContent: "center",
  },
});
