import { Audio } from "expo-av";
type Await<T> = T extends PromiseLike<infer U> ? U : T;

type SoundObject = Await<ReturnType<typeof Audio.Sound.createAsync>>;

class SoundPlayerImp {
  #penSound: SoundObject;
  #popSound: SoundObject;
  #wrongSound: SoundObject;

  async init() {
    this.#penSound = await Audio.Sound.createAsync(
      require("../../assets/sounds/pen.wav")
    );

    this.#popSound = await Audio.Sound.createAsync(
      require("../../assets/sounds/pop.mp3")
    );

    this.#wrongSound = await Audio.Sound.createAsync(
      require("../../assets/sounds/wrong.mp3")
    );
  }

  playPen() {
    this.#penSound.sound.replayAsync();
  }

  playPop() {
    this.#popSound.sound.replayAsync();
  }

  playWrong() {
    this.#wrongSound.sound.replayAsync();
  }
}

export const SoundPlayer = new SoundPlayerImp();
